import React from "react"
import tw from "twin.macro"
import BlockQuoteIh from "./BlockQuoteIh"

const AmbassadorQuote = ({
  ambassadorName,
  image,
  quote,
  timeFootnote,
  addedStyles,
}) => {
  const imageStyle = tw`w-full mb-11 md:(w-1/2 max-w-[320px] mb-0) lg:(max-w-[426px])`

  return (
    <div tw="md:(flex justify-between items-center)" css={addedStyles}>
      <div css={imageStyle}>{image}</div>
      <div tw="md:(w-1/2)">
        <BlockQuoteIh
          author={ambassadorName}
          quote={quote}
          timeFootnote={timeFootnote}
        />
      </div>
    </div>
  )
}

export default AmbassadorQuote
