import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

const BlockQuoteIh = ({
  quote,
  author,
  purpleBar,
  textSmall,
  timeFootnote,
  addedStyles,
}) => {
  return (
    <div css={[addedStyles]}>
      <div css={[purpleBar && tw`pl-8 border-l-2 border-studio`]}>
        <p
          css={[
            tw`font-ffcocon font-light text-studio text-lg leading-[1.44] md:(text-[1.5625rem] leading-[1.31])`,
            textSmall && tw`md:text-2xl`,
          ]}
        >
          <span tw="-ml-[0.4rem] md:(-ml-[0.5rem])">“</span>
          {quote}”
        </p>
        <p tw="my-6 text-base font-bold">
          —{author}, <i tw="font-normal">XYWAV IH Ambassador</i>
        </p>
        <p tw="text-[0.75rem] font-normal">
          Real patient compensated for their{" "}
          {timeFootnote ? "time" : "testimonial"}. Individual results may vary.
        </p>
      </div>
    </div>
  )
}

BlockQuoteIh.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
}

export default BlockQuoteIh
