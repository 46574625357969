import React from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"

// Components
import Container from "@components/container"
import { sectionIh } from "@styles/ui"

const InlineVideoSectionIhRedesign = ({
  video,
  bgColour,
  children,
  isIh = false,
}) => {
  return (
    <section css={[bgColour && bgColour, sectionIh, tw`pt-8`]}>
      <Container isIh={isIh}>
        <div tw="lg:(flex flex-row-reverse justify-between items-center gap-[4.5rem])">
          <div tw="aspect-[500/354] rounded-[24px] overflow-hidden mb-6 lg:(max-w-[31.25rem] w-1/2 mb-0)">
            {video}
          </div>
          <div tw="text-center mb-7 px-4 lg:(text-left mb-0 max-w-[35.625rem] px-0)">
            {children}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default InlineVideoSectionIhRedesign

InlineVideoSectionIhRedesign.propTypes = {
  video: PropTypes.object.isRequired,
  bgColour: PropTypes.object,
}
InlineVideoSectionIhRedesign.defaultProps = {
  bgColour: null,
}
